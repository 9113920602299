import React from 'react';
import L from 'leaflet';

const style = {
  position: 'absolute',
  left: '0',
  top: '0',
  width: '100%',
  height: '100%',
};

/**
 * Ref.: https://cherniavskii.com/using-leaflet-in-react-apps/
 */

class Map extends React.Component {
  componentDidMount() {
    // create map
    this.map = L.map('map', {
      crs: L.CRS.Simple,
      minZoom: -2,
      // center: [40.697632, -98.461165],
      // zoom: 5,
      // layers: [
      //   L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      //     attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      //   }),
      // ]
    });

    // image size: 1988 × 1270
    const imageUrl = 'floor-plan.jpeg';
    const bounds = [[0, 0], [1270, 1988]];
    L.imageOverlay(imageUrl, bounds).addTo(this.map);

    this.map.fitBounds(bounds);

    // const icon = L.icon({ iconUrl: 'icon-alert.png', iconSize: [32, 32] });
    // L.marker(L.latLng([ 145, 175.2 ]), { icon, title: 'Defect' }).addTo(this.map);
    // this.map.setView( [70, 120], 1);

    // add layer
    // this.layer = L.layerGroup().addTo(this.map);
    this.updateMarkers(this.props.markersData);

    this.map.addEventListener('click', (e) => {
      const { latlng } = e;

      this.props.addDefect(latlng);
    });
  }

  componentDidUpdate({ markersData }) {
    // check if data has changed
    if (this.props.markersData !== markersData) {
      this.updateMarkers(this.props.markersData);
    }
  }
  updateMarkers(markersData) {
    // this.layer.clearLayers();

    const icon = L.icon({ iconUrl: 'icon-alert.png', iconSize: [32, 32] });
    markersData.forEach(marker => {
      const item = L.marker(marker.latLng, { icon, title: marker.title }).addTo(this.map);
      item.bindPopup(marker.title);
    });
  }
  render() {
    return <div id='map' style={style} />;
  }
}

export default Map;
