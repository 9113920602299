import React, { useState } from 'react';
// import Map from './Map';
import MapImage from './MapImage';
import './PDFViewer.css';

const PDFViewer = () => {
  const initialState = [{
    latLng: [475, 750.2],
    title: "Defect #1"
  }];

  const [defects, updateDefect] = useState(initialState);

  const addDefect = (latlng) => {
    const newDefect = {
      latLng: [latlng.lat, latlng.lng],
      title: `Defect #${defects.length + 1}`
    };

    updateDefect(defects.concat(newDefect));
  };

  // <Map markersData={defects} addDefect={addDefect} />

  return (
    <MapImage markersData={defects} addDefect={addDefect} />
  );
};

export default PDFViewer;